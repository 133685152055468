.badge {
  background: red;
  position: relative !important;
  top: -8px !important;
  border-radius: 50% !important;
  padding: 6px 8px !important;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .header-nav {
    width: auto !important;
    /* flex-direction: row !important;
    justify-content: flex-start !important; */
  }
  .header-navbar {
    /* justify-content: flex-end; */
  }
  .navbar-toggler {
    position: relative;
    top: -42px;
    color: #fff !important;
    background: #fff;
    left: -31px;
  }
  /** Drwaer code */

  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    padding-bottom: 20px;
  }
  .sidenav ul li {
    padding: 5px 5px 5px 32px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  .sidenav ul {
    padding-left: 18px;
  }
  .sidenav label {
    padding-left: 18px;
    font-size: 20px;
  }
  .sidenav h6 {
    padding-left: 18px;
  }
  .sidenav .btn {
    padding-left: 18px !important;
  }
  .sidenav .closebtn {
    position: absolute;
    top: 72px !important;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    display: block !important;
    width: 20px !important;
    color: #fff;
    text-decoration: none !important;
  }
  .hamburder-icon {
    font-size: 15x;
    background: #fff;
    padding: 7px 15px !important;
    width: 45px !important;
    cursor: pointer;
    border-radius: 6px;
    position: relative;
    /* top: -40px; */
    float: left;
    display: block !important;
  }
  .header-nav {
    display: block !important;
  }
  .header-nav svg {
    font-size: 34px;
  }
  .custom-close {
    position: fixed;
    /* overflow: hidden; */
    /* position: relative; */
    color: #fff !important;
    /* background: #fff; */
    right: 30px;
    z-index: 10000;
    top: 52px;
    font-size: 35px;
  }
  .custom-close-empty {
    position: relative;
    /* overflow: hidden; */
    /* position: relative; */
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: block !important;
    /* flex-basis: auto; */
  }
  .sidenav {
    width: 100% !important;
  }
  .header-nav {
    display: none;
  }
}
.hamburder-icon {
  display: none;
}
.sidenav .closebtn {
  display: none;
}

.custom-close-empty {
  color: #343a40;
  text-decoration: none;
  background-color: transparent;
}

@media (max-width: 767px) {
  .type-container {
    margin-left: 20px;
  }
}
