ul li {
  color: #fff;
  list-style: none;
  padding-left: 2px !important;
}
ul {
  padding-left: 4px;
  line-height: 40px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .desc {
    padding: 2px !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .price-section {
    flex: 0 0 50% !important;
    max-width: 50% !important;
    margin: 0px !important;
  }
  .margin {
    margin-bottom: 2px !important;
  }
  .bg-dark .row {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px !important;
  }
  .available {
    margin-bottom: 12px !important;
  }
  .btn {
    margin-bottom: 8px !important;
  }
  .table thead tr th {
    color: #fff !important;
  }
  .action-btns {
    transform: translate(60px, -46px);
    display: block !important;
  }
  .cartnav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    padding-bottom: 20px;
  }
  .cartnav .closebtn {
    position: absolute;
    top: 75px;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    display: block !important;
    width: 20px !important;
    color: #fff;
    text-decoration: none !important;
    display: block;
  }
  .card:first-child {
    margin-top: 50px !important;
  }
  .empty-text {
    margin: 50px 0px;
  }
}

@media only screen and (min-width: 768px) {
  .mr-auto,
  .mx-auto {
    margin-right: -50px !important;
  }
}

.cartnav .closebtn {
  display: none;
}
